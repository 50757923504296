import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {BusinessUnit, BusinessUnitDataSource} from '../../../misc/BusinessUnitDataSource';
import {BusinessUnitService} from '../../../services/business-unit.service';
import {AuthService} from '../../../auth/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadComponent } from 'src/app/components/common/file-upload/file-upload.component';

@Component({
  selector: 'app-business-units-overview',
  templateUrl: './business-units-overview.component.html',
  styleUrls: ['./business-units-overview.component.scss']
})
export class BusinessUnitsOverviewComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['tenantName', 'name', 'id', 'createdAt', 'isActive', 'action'];
  dataSource: BusinessUnitDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private businessUnitService: BusinessUnitService, private authService: AuthService
  ) {
    this.dataSource = new BusinessUnitDataSource(this.businessUnitService, this.authService);
    this.authService.removeFromCookie("siqId");
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dataSource.init(this.paginator, this.sort);
  }

  onSearch(search: string | Event): void {
    if (typeof search === 'string') {
      this.dataSource.search = search;
    } else {
      // @ts-ignore
      this.dataSource.search = search?.target?.value ?? null;
    }
  }

  editBusinessUnit(businessUnit: BusinessUnit): void {
    this.router.navigate([`/tenants/${businessUnit.tenantId}/business-units/${businessUnit.id}/edit`]);
  }

  viewBusinessUnit(businessUnit: BusinessUnit): void {
    this.router.navigate([`/tenants/${businessUnit.tenantId}/business-units/${businessUnit.id}/view`]);
  }

}
