<div class="page-container">
  <div class="w-100">
    <div class="head mb-4">
      <span class="head_name page-title">Manage Tenant</span>
      <span class="button">
        <button class="rounded-pill small" color="primary" mat-flat-button (click)="createTenant()" [hidden]="admin">
          <mat-icon>add</mat-icon>
          CREATE TENANT
        </button>
      </span>
    </div>

    <app-search-card placeholder="Enter Tenant Details Ex. Tenant ID, Tenant Name, Created Date etc..."
      label="SEARCH TENANT" (search)="onSearch($event)" [hidden]="admin">
    </app-search-card>

    <mat-card class="mt-4">
      <mat-card-title class="card-title">Recently Added Tenants</mat-card-title>
      <mat-card-content>
        <div class="loader">
          <app-loader *ngIf="dataSource.loading$ | async" [noText]="true"></app-loader>
        </div>

        <table [dataSource]="dataSource" [ngClass]="{loading: dataSource.loading$ | async}" class="w-100 custom-table"
          mat-table matSort matSortActive="createdAt" matSortDirection="desc" matSortDisableClear>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>TENANT NAME</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>DISPLAY NAME</th>
            <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>TENANT ID</th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
          </ng-container>

          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>CREATED DATE</th>
            <td mat-cell *matCellDef="let element"> {{element.createdAt | date : 'MM/dd/yyyy'}} </td>
          </ng-container>

          <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>STATUS</th>
            <td mat-cell *matCellDef="let element">{{element.isActive ? 'ACTIVE' : 'INACTIVE'}}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-center">ACTION</th>
            <td mat-cell *matCellDef="let element">
              <div class="w-100 text-center">
                <button (click)="viewTenant(element)" class="mat-elevation-z1 small mr-2" color="primary" mat-mini-fab>
                  <mat-icon>visibility</mat-icon>
                </button>
                <button (click)="editTenant(element)" class="mat-elevation-z1 small" color="primary" mat-mini-fab [hidden]="admin" [disabled]="admin">
                  <mat-icon>mode_edit</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
              No tenants found
            </td>
          </tr>
        </table>
        <mat-paginator #paginator [pageSize]="20" appCustomPaginator aria-label="Select page">
        </mat-paginator>

      </mat-card-content>
    </mat-card>

  </div>
</div>
