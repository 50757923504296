import { AfterViewInit, Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SiteService } from '../../../../services/site.service';
import { ToasterService } from '../../../../services/toaster.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Requests, uploadedFile, UploadHistoryDataSource } from 'src/app/misc/UploadHistoryDataSource';
import { NgxSpinnerService } from 'ngx-spinner';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UPLOADING_STATUS } from 'src/app/app.constants';
import { AuthService } from 'src/app/auth/services/auth.service';
import { FileUploadService } from 'src/app/services/file-upload.service';

@Component({
    selector: 'upload-manager',
    templateUrl: './upload-manager.html',
    styleUrls: ['./upload-manager.scss']
})
export class UploadManagerComponent implements OnInit, AfterViewInit {

    loading = false;
    displayedColumns: string[];
    dataSource: UploadHistoryDataSource;
    data$: any;
    reason: String = '';
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private spinner: NgxSpinnerService, private router: Router, private authService: AuthService, private fileService: FileUploadService, 
      private siteService: SiteService, private toaster: ToasterService, @Inject(MAT_DIALOG_DATA) public data: any) {
        // this.dataSource = new UploadHistoryDataSource(data.files);
        this.dataSource = new UploadHistoryDataSource(fileService, authService);
    }

    ngOnInit(): void {
      this.displayedColumns = ['id', 'name', 'fileType', 'createdAt', 'totalCount', 'uploadStatus', 'processStatus', 'action'];
      this.dataSource.loadData().subscribe(res => {
        this.data$ = res.data;
      });
    }

    ngAfterViewInit(): void {
        this.dataSource.init(this.paginator, this.sort);
    }

    download(fileId: string, fileName: string) {
        this.spinner.show()
        return this.fileService.downloadFile(fileId).subscribe(
          (response: Blob) => {
              if (response) {  
                const url = window.URL.createObjectURL(response["body"]);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileId + "_" + fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                a.remove();
              } else {
                  this.toaster.warning("File cannot be downloaded")
              }
          },
          err => {
              if (err?.error?.message) {
                  this.toaster.error(err.error.message.split(',').join('\n'));
                } else {
                  this.toaster.error('Something went wrong. Please try again.');
                }
          }).add(() => {
          this.spinner.hide();
      });
    }

    fileFormat(name: string) {
      if (name.toLowerCase().includes("csv")) {
        return "CSV";
      } else if (name.toLowerCase().includes("txt")) {
        return "TXT";
      }
    }

    uploadProgress(status: string) {
      if (UPLOADING_STATUS.includes(status)) {
        return true;
      } else {
        return false;
      }
    }
}
