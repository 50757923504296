import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomDataSource, Page} from './CustomDataSource';
import { FileUploadService } from '../services/file-upload.service';
import { AuthService } from '../auth/services/auth.service';

export class UploadHistoryDataSource extends CustomDataSource<Requests> {

  constructor(private fileService: FileUploadService, private authService: AuthService) {
    super();
  }

  loadData(): Observable<Page<Requests>> {
    let observable: Observable<any>;
    if (this.authService.getFromCookie("userId")) {
      observable = this.fileService.getAllFiles(this.authService.getFromCookie("userId"));
    } else {
      observable = this.fileService.getAllFiles(this.authService.getFromCookie("uid"));
    }
    return observable.pipe(map(res => ({data: res.requests, total: res.requests.length})));
  }
}

export interface Requests {
  requestId: string;
  tenantId: string;
  fileType: string;
  timeStamp: Date;
  files: uploadedFile[];
}

export interface uploadedFile {
  fileName: string;
  fileId: string;
  totalRecords: string;
  status: string;
  timeStamp: Date;
}