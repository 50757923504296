import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteService } from '../../../services/site.service';
import { ToasterService } from '../../../services/toaster.service';
import { MatTableDataSource } from '@angular/material/table';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { MAX_FILE_NUM } from 'src/app/app.constants';
import { AuthService } from 'src/app/auth/services/auth.service';


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    @Input()
    placeholder: string;

    fileName: string = "";
    selectedFiles: any[] = [];
    loading: boolean = false;
    displayedColumns: string[] = ['name', 'size', 'action'];
    dataSource = new MatTableDataSource(this.selectedFiles);
    uploadButton: boolean = true;
    maxFileNum = MAX_FILE_NUM;
    uploadedFiles: any[] = [];
    requestId: string = '';
    selectedFileType: string = '';
    fileTypes: any[] = [];
    delimiter:any[] = [''];
    selectedDelimiter: string = '';

    constructor(private spinner: NgxSpinnerService, 
        private toaster: ToasterService,
        private siteService: SiteService,
        private route: ActivatedRoute,
        private router: Router,
        private uploadService: FileUploadService,
        private authService: AuthService){

    }

    ngOnInit(): void {
        this.uploadService.getAllFileTypes().subscribe({
            next: (response) => {
                response.forEach((fileType) => this.fileTypes.push(fileType.fileTypeName));
            }
        }) ;
    }

    clear() {
        this.selectedFiles = null;
        this.fileName = null;
        this.delimiter = null;
    }

    onFileDropped($event) {
        this.prepareFilesList($event);
    }

    onFileTypeChange() {
        this.delimiter = [];
        this.uploadService.getFileType(this.selectedFileType).subscribe(res => {
            if (!this.delimiter.includes(res["delimiter"])) {
                this.delimiter.push(res["delimiter"]);
            }
        })
    }

    fileBrowseHandler(files) {
        this.prepareFilesList(files);
        this.uploadButtonToggle();
    }

    prepareFilesList(files: Array<any>) {
        for (const item of files) {
            item.progress = 0;
            this.selectedFiles.push({
                name: item.name,
                size: item.size
            });
        }
        this.uploadedFiles = Array.from(files);
        this.dataSource._updateChangeSubscription();
    }

    uploadButtonToggle() {
        if (this.dataSource.data.length <= MAX_FILE_NUM && this.dataSource.data.length > 0 && this.selectedFileType != '') {
            this.uploadButton = false;
        } else {
            this.uploadButton = true;
        }
    }

    formatBytes(bytes, decimals) {
        if (bytes === 0) {
          return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    deleteFile(index: number) {
        this.selectedFiles.splice(index, 1);
        this.dataSource._updateChangeSubscription();
        this.uploadButtonToggle();
    }

    upload() {
        const formData = new FormData();
        
        this.uploadedFiles.forEach((file, index) => {
            formData.append('files', file, file.name);
            console.log(file);
        });

        this.uploadService.uploadFiles(this.selectedFileType, this.authService.getFromCookie("tenantId"),this.selectedDelimiter, formData).subscribe({
            next: () => this.uploadService.showStatusCard(true),
            error: (error) => {
                if (error.error.apierror) {
                    this.toaster.error("File Upload Failed: " + error.error.apierror.message)
                } else {
                    this.toaster.error("File Upload Failed due to APIGEE error.");
                }
            }
            });
        
        this.uploadService.changeFiles(this.selectedFiles);
        this.uploadService.triggerRefresh();
        
    }
}
