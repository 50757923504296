import { Component, Input, AfterViewInit, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { SitesDataSource } from '../../../misc/SitesDataSource';
import { SiteService } from '../../../services/site.service';
import {AuthService} from '../../../auth/services/auth.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FileUploadComponent } from 'src/app/components/common/file-upload/file-upload.component';

@Component({
  selector: 'app-business-units-sites',
  templateUrl: './business-units-sites.component.html',
  styleUrls: ['./business-units-sites.component.scss']
})
export class BusinessUnitsSitesComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['name', 'id', 'createdAt', 'isActive', 'action'];
  @Input() tenantId;
  @Input() buId;
  dataSource: SitesDataSource;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  superAdmin: boolean = this.authService.getFromCookie("superAdmin") == "true";

  constructor(private router:Router, private siteService:SiteService, private authService: AuthService, private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: File) {
    this.dataSource = new SitesDataSource(siteService);
   }

   ngAfterViewInit(): void {
    this.dataSource.init(this.paginator, this.sort, {buId: this.buId});
  }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogRef = this.dialog.open(FileUploadComponent, {
      height: '80%',
      width: '70%',
      panelClass: 'custom-dialog-container',
      autoFocus:false
    });
  }

  onClickAdd(){
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/new`]);
  }

  viewBusSite(site:any){
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${site.id}/view`]);

  }

  editBusSite(site:any){
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/sites/${site.id}/edit`]);
  }

  gotoUploads() {
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/uploads`]);
  }
}
