export const REST_ENDPOINT = {
    auth: {
        validate: '/v1/api/cookie',
        verifyToken: '/v1/api/token/verify',
        fetchToken: '/v1/api/token',
        deleteTokn: '/v1/api/cookie/delete'
    },
    user: {
        fetch: '/v1/api/user/info',
        create: '/v1/api/user',
        getAllUsers: '/v1/api/user/all/',
        get: '/v1/api/user',
        update: '/v1/api/user',
        getTenantId: '/v2/api/tenant/'
    },
    superAdmins: {
        get: '/v1/api/user/superadmins',
        create: '/v1/api/user/superadmin'
    },
    orgs: {
        add: '/v1/api/buorg'
    },
    tenants : {
        get: '/api/tenant',
        add: '/api/tenant',
        search: '/api/tenant/search',
        ssoId: '/api/tenant/sso/'
    },
    createTenants : {
        get: '/api/tenants/all',
        add: '/api/tenant/create',
        update: '/api/tenant/update'
    },
    businessUnits : {
        add: '/api/bu/create',
        findAll: '/api/bu/all',
        search: '/api/bu/search',
        findByTenant: '/api/bu/tenantId/',
        get: '/api/bu/',
        update: '/api/bu/update',
        getAllLabels: '/api/bu/labels/',
        IDtoName: '/api/bu/orgids'
    },
    sites: {
      create: '/api/site/create',
      update: '/api/site/update',
      findAll: '/api/site/all',
      search: '/api/site/search',
      findById: '/api/site',
      findKeys: '/api/site/keys',
      findLabels: '/api/site/labels',
      findByBu: '/api/site/businessUnitId/',
      uploadCSV: '/api/site/csv/upload',
      uploadHistory: '/api/site/csv/list',
      downloadCSV: '/api/site/csv/download'
    },
    floors: {
        create: '/api/floor/create',
        findById: '/api/floor/floorId',
        findKeys: '/api/floor/keys',
        update: '/api/floor/update',
        findBySiteId: '/api/floor/all',
    },
    zones: {
        findKeys: '/api/zone/keys',
        add: '/api/zone/create',
        findById : '/api/zone/zoneId',
        findByFloorId: '/api/zone/floorId',
        findChildZoneByParentId: '/api/zone/',
        update:  '/api/zone/update',
    },
    marketSegments: {
      get: '/api/market-segments'
    },
    keys: {
        get:'/api/tenant/keys'
    },
    locationInformation: {
        createCity: '/api/address/createCity',
        getAllCountries: '/api/address/countries',
        getAllStatesByCountryCode: '/api/address/states/',
        getAllCitiesByCountryCodeAndStateCode: '/api/address/cities/'
    },
    tenant:{
        get:'/api/tenant/'
    },
    subscriptions:{
        get:'/api/subscription',
        getByParent: '/api/subscription/parent'
    },
    devices: {
        keys: '/api/device/keys',
        get: '/api/device',
        getAllDevices: '/api/device/all',
        getAllDevicesbySite: '/api/device/all/siteId/',
        getAllChildDevices: '/api/device/parentId/',
        create: '/api/device/create',
        update: '/api/device/update',
        getDeviceTypes: '/api/device/deviceType',
        search: '/api/device/search',
        filter: '/api/device/all/filtered',
    },
    address: {
      get: '/api/address/timezones/'
    },
    files: {
        getAllFiles: '/all/status/',
        getStatusByRequestId: '/status/request',
        getStatusByFileId: '/status/file',
        upload: '/upload',
        getStatusByUsername: '/latest/status',
        download: '/files/',
        getFileType: '/fileTypes',
        getAllFileTypes: '/fileTypes/all'
    }
}

export const FILE_UPLOAD = {
    MAXIMUM_SIZE_KB: 150,
    ALLOWED_FILE_TYPES: ["image/svg+xml"]
}

export const MAC_ADDRESS_REGEX = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})|([0-9a-fA-F]{4}.[0-9a-fA-F]{4}.[0-9a-fA-F]{4})$/

export const UPLOADING_STATUS = ["Payload_Size_Validating", "File_Size_Validating", "File_Format_Validating", "Duplicate_Check", "Malware_Scanning", "Vulnerability_Scanning"]

export const MAX_FILE_NUM = 10;