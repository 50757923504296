import {Component, HostBinding, Input, OnInit} from '@angular/core';
import { of, Subscription } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { UPLOADING_STATUS } from 'src/app/app.constants';
import { AuthService } from 'src/app/auth/services/auth.service';
import { FileUploadService } from 'src/app/services/file-upload.service';

@Component({
  selector: 'upload-card',
  templateUrl: './upload-card.component.html',
  styleUrls: ['./upload-card.component.scss']
})
export class UploadCardComponent implements OnInit {
  collapsed:boolean = true;
  filesUploaded: any;
  fileList: any = [];

  success: number = 0;
  failed: number = 0;
  progress: number = 0;
  updating_array = UPLOADING_STATUS;
  isVisible = false;
  subscription: Subscription;
  

  constructor(private uploadService: FileUploadService, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.updateData();
    this.subscription = this.uploadService.refresh$.subscribe(() => {
      this.updateData();
    });
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
  }

  updateData() {
    this.fileList = [];
    this.success = 0;
    this.failed = 0;
    this.progress = 0;
    
    let user = this.authService.getFromCookie("userId") ?? this.authService.getFromCookie("uid");
    
    of(null).pipe(delay(300), switchMap(() => this.uploadService.getStatusByUsername(user)),
    switchMap((firstResponse) => {
      let requestId = firstResponse["requestId"];
      return of(firstResponse).pipe(
        delay(500),
        switchMap(() => this.uploadService.getStatusByRequestId(requestId))
      );
    })
    ).subscribe(secondResponse => {
        this.filesUploaded = secondResponse;
        if (this.filesUploaded !== undefined) {
          if (this.filesUploaded.nonFailureResponse !== undefined) {
            for (var file of this.filesUploaded.nonFailureResponse) {
              if (file.status == 'File_Uploaded') {
                this.success++;
              } else {
                this.progress++;
              }
              this.fileList.push(file);
            }
          }
          
          if (this.filesUploaded.failureResponse !== undefined) {
            for (var file of this.filesUploaded.failureResponse) {
              this.failed++;
              this.fileList.push(file);
            }
          }
        }
    
        this.uploadService.statusCardVisibility$.subscribe((visible) => {
          this.isVisible = visible;
        });
      });
    };

  @HostBinding('class.is-expanded')
  get isExpanded() {
    return this.collapsed;
  }

  receiveFiles(files: File[]) {
    this.filesUploaded = files;
  }

  close() {
    this.isVisible = false;
    this.uploadService.showStatusCard(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
