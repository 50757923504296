import { formatDate } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationInformationComponent } from 'src/app/components/common/location-information/location-information.component';
import { ArrayValidators } from 'src/app/misc/CustomValidators';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import { TenantService } from 'src/app/services/tenant.service';
import { dateValidation } from 'src/app/validators/date-field.validators';
import {ToasterService} from '../../../services/toaster.service';
import {Clipboard} from '@angular/cdk/clipboard';
import { MatTabChangeEvent } from '@angular/material/tabs';
import {AuthService} from '../../../auth/services/auth.service';
import { FileUploadComponent } from 'src/app/components/common/file-upload/file-upload.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-create-business-unit',
  templateUrl: './create-business-unit.component.html',
  styleUrls: ['./create-business-unit.component.scss']
})
export class CreateBusinessUnitComponent implements OnInit {
  buForm: UntypedFormGroup;
  @Input() isReadonly;
  @Input() tenantValue;
  validInformation: Boolean = false;
  errorMessage: any;
  tenantId: any;
  buId: any;
  mode: any;
  buValue: any;
  @ViewChild(LocationInformationComponent) locationInfoChild: LocationInformationComponent;
  loader: boolean = true;
  cascadeSubscriptions = [];
  loadingCount = 0;
  selectedTabIndex: number;
  admin: Boolean = this.authService.getFromCookie("admin") == "true";
  buOnlySubList = [];

  get viewMode(): boolean {
    return this.mode === 'view';
  }

  get isCreateMode(): boolean {
    return !this.buId
  }

  get createMode(): boolean {
    return !this.buId;
  }

  get editMode(): boolean {
    return this.mode === 'edit';
  }

  get loading(): boolean {
    return this.loadingCount > 0;
  }


  constructor(private _fb: UntypedFormBuilder, private businessUnitService: BusinessUnitService, private tenantService: TenantService,
    private toaster: ToasterService, private route: ActivatedRoute, private router: Router, private clipboard: Clipboard,
              private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.tenantId = paramMap.get('tenantId');
      this.buId = paramMap.get('buId');
      this.authService.saveInCookie("buOrgId", this.buId);
      this.mode = paramMap.get('mode') ?? (this.buId ? 'view' : 'edit');
      if(!this.buId || this.mode !== 'edit') {
        this.inItForm();
        this.buForm.get('isActive').setValue(true);
      }
    });
    this.route.queryParamMap.subscribe(query => {
      this.selectedTabIndex = parseInt(query.get('selectedTabIndex'));
    });
    this.loadingCount++;
    this.businessUnitService.getSubscriptionsByParent("TENANT", this.tenantId).subscribe(
      (res) => {
        const parentSubscriptions = res["subscriptions"];
        parentSubscriptions.forEach((subscription) => {
          if(subscription.cascade === "Yes") {
            subscription = {...subscription, cascadedSubscription: true};
            this.cascadeSubscriptions.push(subscription);
          }
        })
        if(!this.buId && this.cascadeSubscriptions?.length) {
          this.populateCascadeSubscriptions();
        }
        if(this.buId && this.mode === 'edit') {
          this.inItForm();
          this.buForm.get('isActive').setValue(true);
        }
      },
      (err) => {
        this.toaster.error("Please enter a deactivation date in Tenant's subscriptions");
      }
    ).add(() => {
      this.loadingCount--;
    })
  }

  onTabChange(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    // do something based on selected tab index
  }

  populateCascadeSubscriptions() {
      const subscriptions = this.buForm?.get('subscriptions') as UntypedFormArray;
      subscriptions.clear();
      for(let subscription of this.cascadeSubscriptions) {
        let dummyKey = this._fb.group({
          productCode: [''],
          cascade: [''],
          portfolio: [''],
          activationDate: ['', [dateValidation('deactivationDate')]],
          deactivationDate: ['', [dateValidation('activationDate')]],
          cascadedSubscription: [''],
          editing: [false]
        })
        dummyKey.patchValue(subscription)
        subscriptions?.push(dummyKey);
      }
      for(let index = 0; index < this.cascadeSubscriptions?.length; index++) {
        const control = subscriptions.controls[index] as UntypedFormGroup;
        let disabledCtrl = this._fb.control(true);
        control.setControl('_disabled', disabledCtrl);
      }
  }

  inItForm() {
    this.buForm = this._fb.group({
      name: ["", [Validators.required]],
      dunsNumber: ["", [Validators.pattern('^[a-zA-Z0-9]*$')]],
      serviceMaxAccount: this._fb.group({
        id: ["", [Validators.pattern('^[a-zA-Z0-9]*$')]]
      }),
      tenantId: this.tenantId,
      remedyTvcId: [""],
      isActive: ["", [Validators.required]],
      keys: this._fb.array([]),
      labelStructure: this._fb.array([]),
      marketSegment: this._fb.array([], [ArrayValidators.minLength(1)]),
      subscriptions: this._fb.array([])
    })

    if (this.buId) {
      this.loadBusinessUnit();

    }
    else {
      this.loader = false
      this.loadingCount--;
    }
  }

  onSubmit() {
    if (this.buForm.invalid) {
      this.buForm.markAllAsTouched();
      this.toaster.error('Form contains some errors');
      return;
    }
    const payload = this.buForm.value;
    const results = this.buForm.value.keys.filter(element => {
      if (Object.keys(element.name)?.length !== 0) {
        return true;
      }
      return false;
    });
    let keys = {
      keys: results
    }
    const labelsArray = this.buForm.value.labelStructure.filter(element => {
      if (Object.keys(element.name)?.length !== 0) {
        return true;
      }
      return false;
    });
    let labelStructure = {
      labelStructure: labelsArray
    }

    const resultsSubscription = this.buForm.value.subscriptions.filter(element => {
      if (Object.keys(element.productCode)?.length !== 0) {
        return true;
      }
      return false;
    });
    resultsSubscription.forEach(element => {
      element.activationDate = formatDate(element.activationDate, "yyyy-MM-dd", "en-us");
      element.deactivationDate = element.deactivationDate? formatDate(element.deactivationDate, "yyyy-MM-dd", "en-us") : element.deactivationDate;
    });
    let subscriptions = {
      subscriptions: resultsSubscription
    }
    if (this.validInformation) {
      if (this.createMode) {
        this.createBu(payload, keys, subscriptions, labelStructure);
      }
      else {
        this.updateBu(payload, keys, subscriptions, labelStructure);
      }
    }
    else {
      this.toaster.error('Please validate the address');
    }

  }

  createBu(payload, keys, subscriptions, labelStructure) {
    let forCreate = Object.assign(payload, keys, subscriptions, labelStructure);
    this.loadingCount++;
    this.businessUnitService.createBusinessUnit(forCreate).subscribe(
      (res) => {
        this.toaster.success(`Business unit created successfully ${res.id}`);

        this.router.navigate([`business-units`]);
      },
      (err) => {
        this.errorMessage = err.error.apierror ? err.error.apierror.message : 'Please try again';
        if (err.error.code == '400' || err.status == '400') {
          this.toaster.error(err.error.message);
        }
        else {
          this.toaster.error('Please try again');
        }
      }
    ).add(() => {
      this.loadingCount--;
    })

  }

  updateBu(payload, keys, subscriptions, labelStructure) {
    let id = {
      id: this.buValue?.id
    };
    let forUpdate = Object.assign(payload, id, keys, subscriptions, labelStructure);
    this.loadingCount++;
    this.businessUnitService.updateBusinessUnit(forUpdate).subscribe(
      (res) => {
        this.toaster.success('Record updated successfully');
        this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/view`]);
      },
      (err) => {
        if (err?.error?.code == '400' || err?.status == '400') {
          this.toaster.error(err?.error?.message);
        }
        else {
          this.toaster.error('Please try again');
        }
      }
    ).add(() => {
      this.loadingCount--;
    })

  }

  onClickCancel() {
    if (this.createMode) {
      this.router.navigate([`dashboard/view/${this.tenantId}`], {
        queryParams: {
          tab: 'bu'
        }
      });
    }
    else if (this.editMode) {
      this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/view`]);
    }
  }

  OnClickUpdate() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([`/tenants/${this.tenantId}/business-units/${this.buId}/update`]);
    this.loader=true;
  }

  goToBuHome() {
    var buttonText = document.getElementById('backButton').textContent;
    if (buttonText.includes("Back to Tenant Details")) {
      this.router.navigate([`dashboard/view/${this.tenantId}`]);
    } else {
      this.router.navigate([`/business-units`]);
    }
  }

  forLocationValidator(eventData) {
    this.validInformation = !eventData;
  }

  loadBusinessUnit() {
    this.loadingCount++;
    this.businessUnitService.findBusinessUnitsByBuId(this.buId).subscribe(
      (res) => {
        this.loader = false;
        this.buValue = res;
        this.businessUnitService.getSubscriptionsByParent("BUSINESS_UNIT", this.buId).subscribe((res) => {
          this.buOnlySubList = res.subscriptions.filter(a => !this.cascadeSubscriptions.map(b=>b.productCode).includes(a.productCode));
          let subscription = this.cascadeSubscriptions.concat(this.buOnlySubList);
          sessionStorage.setItem("existingSub", JSON.stringify(this.buOnlySubList.map(a => a.productCode)));

          this.buValue.subscriptions = subscription;
          if (this.mode == 'view') {
            this.isReadonly = true;
            this.viewBu();
          }
          else if (this.mode = 'edit') {
            this.isReadonly = false;
            this.viewBu();
          }
        })
      }
    ).add(() => {
      this.loadingCount--;
    })
  }
  viewBu() {
    if (this.buValue != null) {
      const gi = {
        name: this.buValue.name,
        dunsNumber: this.buValue.dunsNumber,
        serviceMaxAccount: {
          id: this.buValue.serviceMaxAccount?.id
        },
        tenantId: this.tenantId,
        remedyTvcId: this.buValue.remedyTvcId,
        isActive: this.buValue.isActive
      }
      this.viewEditKeys();
      this.viewEditSubscription();
      this.viewEditLabelStructure();
      const marketSegment = this.buForm.get('marketSegment') as UntypedFormArray;
      this.buValue.marketSegment.forEach(s => {
        return marketSegment.push(new UntypedFormControl(s));
      });
      this.buForm.patchValue(gi);
      if(this.isReadonly) {
        this.buForm.get('isActive')?.disable();
      }
    }

  }

  viewEditLabelStructure() {
    if (this.buValue != null && this.buValue?.labelStructure) {
      const labelStructure = this.buForm.get('labelStructure') as UntypedFormArray;
      labelStructure.clear();
      for (let item of this.buValue?.labelStructure) {
        let dummyLabel = this._fb.group({
          name: [''],
          values: this._fb.array([]),
          hIndex: [''],
        })
        let values = dummyLabel.get('values') as UntypedFormArray;
        for (let value of item.values) {
          let valueControl = this._fb.control(value);
          values.push(valueControl);
        }

        dummyLabel.patchValue(item);
        dummyLabel.setValidators(this.validateNameAndValues.bind(this));
        dummyLabel.updateValueAndValidity();
        labelStructure?.push(dummyLabel);
        }
    }
  }

  validateNameAndValues(control: AbstractControl): ValidationErrors | null {
    const nameValue = control.get('name').value;
    const valuesArray = control.get('values') as UntypedFormArray;

    const valuesValue = valuesArray.controls.some((control: AbstractControl) => {
      return control.value !== '';
    });

    if ((nameValue && !valuesValue) || (!nameValue && valuesValue)) {
      return { nameAndValuesRequired: true };
    }

    return null;
  }

  viewEditKeys() {
    if (this.buValue != null) {
      const keys = this.buForm?.get('keys') as UntypedFormArray;
      keys.clear();
      for (let item of this.buValue.keys) {
        let dummyKey = this._fb.group({
          name: [''],
          value: ['']
        })
        dummyKey.patchValue(item);
        keys?.push(dummyKey);
      }
    }
  }

  viewEditSubscription() {
    const subscriptions = this.buForm?.get('subscriptions') as UntypedFormArray;
    subscriptions.clear();
    let rearrangedSubscriptions = [];
    if(this.mode === 'view') {
      rearrangedSubscriptions = this.buValue.subscriptions;
    } else {
      const subscriptionsArray1 = this.cascadeSubscriptions.concat(this.subscriptionsRearrangement());
      const subscriptionsArray2 = this.buValue.subscriptions;
      rearrangedSubscriptions = subscriptionsArray1.map(subscription1 => {
        const subscription2 = subscriptionsArray2.find(subscription => subscription.productCode === subscription1.productCode);
        if (subscription2) {
          return { ...subscription1, ...subscription2 };
        }
        return subscription1;
      });
    }
    for (let item of rearrangedSubscriptions) {
      let dummyKey = this._fb.group({
        productCode: [''],
        cascade: [''],
        portfolio: [''],
        activationDate: ['', [dateValidation('deactivationDate')]],
        deactivationDate: ['', [dateValidation('activationDate')]],
        cascadedSubscription: [''],
        editing: [false]
      })
      dummyKey.patchValue(item);
      subscriptions?.push(dummyKey);
    }
    if(this.buValue.subscriptions?.length) {
      for(let index = 0; index < this.buValue.subscriptions?.length; index++) {
        const control = subscriptions.controls[index] as UntypedFormGroup;
        let disabledCtrl = this._fb.control(true);
        control.setControl('_disabled', disabledCtrl);
      }
    }
  }
  subscriptionsRearrangement() {
    const results = this.buValue.subscriptions.filter(({ productCode: value1 }) => !this.cascadeSubscriptions.some(({ productCode: value2 }) => value2 === value1));
    return results;
  }

  showToast(key) {
    this.toaster.info(`${key} copied!`)
  }

  openDialog() {
    const dialogRef = this.dialog.open(FileUploadComponent, {
      height: '90%',
      width: '70%',
      panelClass: 'custom-dialog-container',
      autoFocus:false
    });
  }

}
