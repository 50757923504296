<h2 mat-dialog-title style="text-align: center; margin-bottom: 0;">Upload File</h2>

<div>
  <div style="display: flex; justify-content: center; text-align: center; line-height: 1; margin-bottom: 8px; width: 100%;">
    <h6 mat-dialog-title style="margin: 0; padding: 0; color: red;">Max number of files is {{ maxFileNum }}.</h6>
  </div>
  <div style="display: flex; align-items: center; justify-content: center; width: 45%; margin-left:auto; margin-right: auto;">
    <mat-form-field appearance="fill" style="width: 200px;">
      <mat-label>Select a file type</mat-label>
      <mat-select [(value)]="selectedFileType" (selectionChange)="onFileTypeChange()" required>
        <mat-option *ngFor="let option of fileTypes" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100px; margin-left: 10%;">
      <mat-label>Select delimiter</mat-label>
      <mat-select [(value)]="selectedDelimiter" [disabled]="selectedFileType == ''">
        <mat-option *ngFor="let option of delimiter" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>




<div class="container" appDnd (fileDropped)="onFileDropped($event)" [ngClass]="{ 'disabled-section': !selectedFileType }">
  <h3> Drag and drop file here </h3>
  <h3> or </h3>
  <input type="file" #fileBrowse id="fileBrowse" multiple accept=".txt, .csv" (change)="fileBrowseHandler($event.target.files)" [max]="15" />
  <label for="fileBrowse"> Browse for file </label>
</div>

<h5 style="text-align: center; margin-top: auto; margin-bottom: auto; color: #333333">
  <strong> {{selectedFiles.length}} </strong> files added.
</h5>

<mat-dialog-content>
  <table [dataSource]="dataSource" class="w-100 upload-table" mat-table>
    <ng-container matColumnDef="name">
      <th style="width: 150px;text-align: left;" mat-header-cell *matHeaderCellDef>File Name</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="size">
      <th style="width: 70px;text-align: center;" mat-header-cell *matHeaderCellDef>File Size</th>
      <td style="width: 50px;text-align: center;" mat-cell *matCellDef="let element"> {{formatBytes(element.size)}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th style="width: 70px;text-align: center;" mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let i = index" style="text-align: center;">
        <button mat-icon-button (click)="deleteFile(i)">
          <img src="assets/images/delete.svg" width="20px">
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions>
  <button [mat-dialog-close]="true" (click)="upload()" class="rounded-pill small" color="primary" mat-flat-button type="button"
  [disabled]="uploadButton"> Upload </button>
</mat-dialog-actions>


<ngx-spinner
    bdColor="rgba(0,0,0,0.1)"
    size="medium"
    color="#00539E"
    type="ball-clip-rotate"
  ></ngx-spinner>
