<div style="display: flex; align-items: center; justify-content: space-between; gap: 20px; align-items: baseline;">
  <div class="filter-container">
    <mat-form-field appearance="fill">
      <mat-label>Filter by Date Range</mat-label>
      <mat-select [(value)]="selectedRange" (selectionChange)="applyDateFilter()">
        <mat-option *ngFor="let option of dateRangeOptions" [value]="option.value">
          {{ option.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<mat-card class="mt-4">
  <mat-card-content>
    <div class="loader">
      <app-loader
        *ngIf="dataSource.loading$ | async"
        [noText]="true"
      ></app-loader>
    </div>

    <table
      [dataSource]="data$"
      [ngClass]="{ loading: dataSource.loading$ | async }"
      class="w-100 custom-table"
      mat-table
      matSort
      matSortDirection="desc"
      matSortDisableClear
    >

      <ng-container matColumnDef="id">
        <th *matHeaderCellDef mat-header-cell>Request ID</th>
        <td *matCellDef="let element" mat-cell style="width: 350px;">{{ element.requestId }}</td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date/Time (UTC)</th>
        <td mat-cell *matCellDef="let element" style="width: 160px;">
          {{ element.timeStamp | date : "dd MMM yyyy, HH:mm:ss" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="fileCount">
        <th *matHeaderCellDef mat-header-cell style="text-align: center;">No. of Files</th>
        <td style="text-align: center;" *matCellDef="let element" mat-cell>{{ element.files.length }}</td>
      </ng-container>

      <ng-container matColumnDef="fileType">
        <th *matHeaderCellDef mat-header-cell>File Type</th>
        <td style="text-align: left;" *matCellDef="let element" mat-cell>{{ element.fileType }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell>Status</th>
        <td *matCellDef="let element; let i = index" mat-cell>
          <csv-upload-progress [element]="element"></csv-upload-progress>
        </td>
      </ng-container>

      <ng-container matColumnDef="action" >
        <th *matHeaderCellDef class="text-center" mat-header-cell>ACTION</th>
        <td *matCellDef="let element" mat-cell>
          <div class="w-100 text-center">
            <span class="button">
              <button mat-flat-button (click)="openDialog(element.files, element.timeStamp)">
                <i class="material-icons">open_in_new</i>
              </button>
            </span>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
          No uploads found
        </td>
      </tr>
    </table>

    <mat-paginator #paginator [pageSize]="20" appCustomPaginator aria-label="Select page" (page)="onPageChange($event)" [length]="totalSize">
    </mat-paginator>
    
  </mat-card-content>
</mat-card>
<ngx-spinner
    bdColor="rgba(0,0,0,0.1)"
    size="medium"
    color="#00539E"
    type="ball-clip-rotate"
  ></ngx-spinner>
