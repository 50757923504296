
<div>
  <mat-card class="mt-4">
    <mat-card-content>
      <div class="loader">
      </div>
      <table
        [dataSource]="data.files"
        [ngClass]="{ loading: dataSource.loading$ | async }"
        class="w-100 custom-table"
        mat-table
        matSort
        matSortDirection="desc"
        matSortDisableClear
      >
        <ng-container matColumnDef="id">
          <th *matHeaderCellDef mat-header-cell style="overflow:hidden; white-space: nowrap;">File ID</th>
          <td *matCellDef="let element" mat-cell style="font-size: 13px;">{{ element.fileId }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th *matHeaderCellDef mat-header-cell style="overflow:hidden; white-space: nowrap;">File Name</th>
          <td *matCellDef="let element" mat-cell style="font-size: 13px;">{{ element.fileName }}</td>
        </ng-container>
  
        <ng-container matColumnDef="fileType">
          <th *matHeaderCellDef mat-header-cell style="overflow:hidden; white-space: nowrap;">File Format</th>
          <td *matCellDef="let element" mat-cell style="font-size: 13px;">{{ fileFormat(element.fileName) }}</td>
        </ng-container>
  
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 150px;overflow:hidden; white-space: nowrap;">Date/Time (UTC)</th>
          <td mat-cell *matCellDef="let element" style="font-size: 13px;">
            {{ data.timestamp | date : "dd MMM yyyy, HH:mm:ss" }}
          </td>
        </ng-container>
  
        <ng-container matColumnDef="totalCount">
          <th style="text-align: center; overflow:hidden; white-space: nowrap;" *matHeaderCellDef mat-header-cell>Total Records</th>
          <td style="text-align: center; font-size: 13px" *matCellDef="let element" mat-cell>{{ element.totalRecords }}</td>
        </ng-container>
  
        <ng-container matColumnDef="uploadStatus" >
          <th *matHeaderCellDef mat-header-cell style="overflow:hidden; white-space: nowrap;">Upload Status</th>
          <td *matCellDef="let element" mat-cell style="text-align: center;">
            <span *ngIf="element.status == 'File_Uploaded'" matTooltip="File_Uploaded">
              <i class="material-icons" style="color: green;">check_circle</i>
            </span>
            <span *ngIf="element.status != 'File_Uploaded' && uploadProgress(element.status) == false" [matTooltip]="Error">
              <span class="failed-box">Failed</span>
            </span>
            <span *ngIf="uploadProgress(element.status)" [matTooltip]="element.status" style="font-size: 13px;">Uploading...</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="processStatus">
          <th *matHeaderCellDef mat-header-cell style="overflow:hidden; white-space: nowrap;">Processing Status</th>
          <td *matCellDef="let element" mat-cell style="font-size: 13px; padding-left: 13px;">
            <span *ngIf="element.status == 'File_Uploaded'"> Processing...</span>
            <span *ngIf="element.status != 'File_Uploaded'"> --- </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th *matHeaderCellDef class="text-center" mat-header-cell style="overflow:hidden; white-space: nowrap;">ACTION</th>
          <td *matCellDef="let element" mat-cell>
            <div class="w-100 text-center">
              <span class="button">
                <button class="rounded-pill small mr-2 download-btn" [disabled]="element.status !== 'File_Uploaded'" color="primary" mat-flat-button (click)="download(element.fileId, element.fileName)">
                  DOWNLOAD <img class="download-img" src="../../../../../assets/images/download.svg" />
                </button>
              </span>
            </div>
          </td>
        </ng-container>
      
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
        <tr *matNoDataRow class="mat-row">
          <td
            [attr.colspan]="displayedColumns.length"
            class="mat-cell text-center"
          >
            No uploads found
          </td>
        </tr>
      </table>
      <mat-paginator
        #paginator
        [pageSize]="20"
        appCustomPaginator
        aria-label="Select page"
        hidden
      >
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
<ngx-spinner
    bdColor="rgba(0,0,0,0.1)"
    size="medium"
    color="#00539E"
    type="ball-clip-rotate"
  ></ngx-spinner>
