<div *ngIf="loading" class="loader-container">
    <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
    </div>
    <h1>Loading Business Unit Information...</h1>
</div>
<div *ngIf="!loading" class="page-container">
    <div class="d-flex info-header">
        <div *ngIf="buId">
            <button class="rounded-pill small" color="primary" mat-flat-button (click)="goToBuHome()" id="backButton">
                <mat-icon>arrow_back</mat-icon>
              {{ this.admin == true ? "Back to Tenant Details":"BU Home" }}
            </button>
        </div>
        <div class="ml-3 my-auto">
            <span>TENANT ID:&nbsp;<strong>{{tenantId}}</strong></span>
            <span *ngIf="!buId"> / ADD BUSINESS UNITS</span>
            <span *ngIf="buId">&nbsp;|&nbsp;BU ID:&nbsp;<strong>{{buId}}</strong></span>
        </div>
    </div>
    <mat-tab-group #tabGroup [(selectedIndex)]="selectedTabIndex" class="card-tabs" [class.hide-tabs]="createMode">
        <mat-tab label="Business Unit Info">
            <span class="button" [hidden]="!viewMode">
                <button class="rounded-pill small" mat-button color="primary" mat-flat-button (click)="openDialog()">
                  <mat-icon>upload</mat-icon>UPLOAD FILE
                </button>
            </span>
            <form [formGroup]="buForm" (ngSubmit)="onSubmit()" class="mb-4" [class.read-only]="isReadonly">
                <div class="text-right mt-4">
                    <ng-container *ngIf="!viewMode">
                        <button (click)="onClickCancel()" class="rounded-pill small mr-4 border border-primary" color="primary"
                            mat-button type="button">
                            CANCEL
                        </button>
                        <button class="rounded-pill small" color="primary" mat-flat-button type="submit">
                            {{buId ? 'UPDATE' : 'ADD'}}
                        </button>
                    </ng-container>
                    <button (click)="OnClickUpdate()" *ngIf="viewMode" class="rounded-pill small" color="primary"
                        mat-flat-button type="button" [disabled]="admin" [hidden]="admin">
                        UPDATE
                    </button>
                </div>
                <section>
                    <h2>General Information</h2>
                    <mat-card>
                        <mat-card-content>
                            <mat-grid-list cols="24" gutterSize="16px" rowHeight="70px">
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>Name</mat-label>
                                        <input matInput type="text" [placeholder]="isReadonly ? '' : 'Enter Name Here'" formControlName="name"
                                            minlength="4" maxlength="100" [required]="!isReadonly" [readonly]='isReadonly'>
                                        <mat-error *ngIf="buForm.controls['name'].hasError('required')">You must enter a
                                            business unit name</mat-error>
                                        <mat-error *ngIf="buForm.controls['name'].hasError('minlength')">Business unit name
                                            must be 4-100 chars</mat-error>
                                        <mat-error *ngIf="buForm.controls['name'].hasError('maxlength')">Business unit name
                                            must be 4-100 chars</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>DUNS Number</mat-label>
                                        <input matInput [placeholder]="isReadonly ? '' : 'Enter DUNS number'" formControlName=dunsNumber maxlength="30"
                                            [readonly]='isReadonly'>
                                        <mat-error *ngIf="buForm.controls['dunsNumber'].hasError('pattern')">Special char. not allowed</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                                <mat-grid-tile formGroupName="serviceMaxAccount" class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>Service Max Account</mat-label>
                                        <input matInput [placeholder]="isReadonly ? '' : 'Enter Service Max Account'" formControlName=id maxlength="30"
                                            [readonly]='isReadonly'>
                                        <mat-error *ngIf="buForm.get('serviceMaxAccount.id').hasError('pattern')">Special char. not allowed</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <mat-grid-list cols="24" gutterSize="16px" rowHeight="70px">
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <span class="w-100 small">
                                            <mat-label>Tenant ID</mat-label>
                                            <mat-icon
                                              class="copy-button"
                                              (click)="showToast('Tenant ID')"
                                              *ngIf="isReadonly"
                                              matRipple
                                              matSuffix
                                              matRippleColor="white"
                                              [cdkCopyToClipboard]="this.buForm.get('tenantId').value"
                                              >content_copy</mat-icon
                                            >
                                          </span>
                                        <input matInput [placeholder]="isReadonly ? '' : 'Enter Service Max Account'" formControlName=tenantId
                                            [readonly]=true
                                            [title]="tenantId">
                                    </mat-form-field>
                                </mat-grid-tile>

                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>Remedy TVC ID</mat-label>
                                        <input matInput [placeholder]="isReadonly ? '' : 'Enter remedy-TVC ID here'" formControlName=remedyTvcId
                                        [readonly]='isReadonly'>
                                        <mat-error *ngIf="buForm.controls['remedyTvcId'].hasError('required') && !isReadonly">You must enter a
                                            Remedy TVC ID</mat-error>
                                    </mat-form-field>
                                </mat-grid-tile>
                                <mat-grid-tile class="header" [colspan]="8">
                                    <mat-form-field floatLabel="always">
                                        <mat-label>Status</mat-label>
                                        <mat-select [placeholder]="isReadonly ? '' : 'Select status'"
                                            formControlName=isActive [required]="!isReadonly">
                                            <mat-option [value]="true">Active</mat-option>
                                            <mat-option [value]="false">Inactive</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </mat-card-content>
                    </mat-card>
                </section>
                <section>
                    <h2>Keys</h2>
                    <app-keys [isReadonly]="isReadonly" [mode]="mode" [id]="buValue?.id" ></app-keys>
                </section>
                <section>
                    <h2>Location Information</h2>
                    <app-address-validation [isReadonly]='isReadonly' [location]="buValue?.location" (locationValidator)="forLocationValidator($event)">
                    </app-address-validation>
                </section>

                <section>
                    <h2>Labels</h2>
                    <app-label-structure [isReadonly]='isReadonly' [isCreateMode]="isCreateMode">
                    </app-label-structure>
                </section>

                <section>
                    <h2>Market Segment</h2>
                    <app-market-segment [readOnly]='isReadonly'></app-market-segment>
                </section>

                <section>
                    <h2>Subscriptions</h2>
                    <app-subscriptions [readOnly]='isReadonly' [isCreateMode]="isCreateMode"></app-subscriptions>
                </section>

            </form>
        </mat-tab>
        <mat-tab *ngIf="!createMode" label="Sites">
            <app-business-units-sites [tenantId]='tenantId' [buId]='buId'></app-business-units-sites>
        </mat-tab>
    </mat-tab-group>
</div>
