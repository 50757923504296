import { Component, OnInit } from '@angular/core';
import { FileUploadService } from 'src/app/services/file-upload.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isVisible = false;

  constructor(private uploadService: FileUploadService) { }

  ngOnInit(): void {
    this.uploadService.statusCardVisibility$.subscribe((visible) => {
      this.isVisible = visible;
    });
  }
}
