import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective} from '@angular/forms';
import { BusinessUnitService } from 'src/app/services/business-unit.service';
import {FormPart} from '../../tenants/create-tenant/create-tenant.component';
import { dateValidation } from 'src/app/validators/date-field.validators';
import { MatDatepicker } from '@angular/material/datepicker';
import {ActivatedRoute, Router} from '@angular/router';
import {filter} from 'rxjs/operators';


@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
  viewProviders: [FormPart]
})
export class SubscriptionsComponent implements OnInit {
  @Input() readOnly: boolean;
  @Input() isCreateMode: boolean;
  // productCodes = [1, 2, 3, 4, 5];
  types = [
    'Yes',
    'No'
  ]
  productData = []
  maxDate = new Date('2100-12-31');
  tenantId:any;
  buId:any;
  subOptions = [];
  placeHolder:string = "Product Code";
  filteredOptions = [];


  constructor(private fb: UntypedFormBuilder, private router: Router, private route: ActivatedRoute, private readonly formGroupDirective: FormGroupDirective, private businessUnitService: BusinessUnitService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.tenantId = paramMap.get('tenantId');
      this.buId = paramMap.get('buId');
    });
    if (this.isCreateMode) {
      this.addSubscription();
    }
    if (this.router.url.includes('/dashboard/view') || this.router.url.includes('/dashboard/update') || this.router.url.includes('/dashboard/createTenant')) {
      this.businessUnitService.getAllSubscriptions().subscribe(data => {
        if(data){
          this.productData = data.subscriptions;
          this.subOptions = data.subscriptions;
          let existingSub = JSON.parse(sessionStorage.getItem("existingSub"));
          if (existingSub.length != 0) {
            this.subOptions = this.subOptions.filter(function(o1) {
              return !existingSub.some(function (o2) {
                return o1.productCode === o2;
              });
            })
          }
        }
        sessionStorage.removeItem("existingSub");
      })
    } else if (this.router.url.includes('/sites')) {
      this.businessUnitService.getSubscriptionsByParent("BUSINESS_UNIT", this.buId).subscribe((res) => {
        this.productData = res.subscriptions;
        this.subOptions = res.subscriptions.filter(a => a.cascade == "No");
        let existingSub = JSON.parse(sessionStorage.getItem("existingSub"));
        if (existingSub.length != 0) {
          this.filteredOptions = this.subOptions.filter(function(o1) {
            return !existingSub.some(function (o2) {
              return o1.productCode === o2;
            });
          })
        }
        if (this.subOptions.length == 0 && this.filteredOptions.length == 0) {
          this.placeHolder = "No subscriptions available"
        } else if (this.filteredOptions.length != 0 && this.subOptions.length != 0) {
          this.subOptions = this.filteredOptions;
        }
        sessionStorage.removeItem("existingSub");
      })
    } else {
      this.businessUnitService.getSubscriptionsByParent("TENANT", this.tenantId).subscribe((res) => {
        this.productData = res.subscriptions;
        this.subOptions = res.subscriptions.filter(a => a.cascade == "No");
        let existingSub = JSON.parse(sessionStorage.getItem("existingSub"));
        if (existingSub !== undefined && existingSub.length != 0) {
          this.filteredOptions = this.subOptions.filter(function(o1) {
            return !existingSub.some(function (o2) {
              return o1.productCode === o2;
            });
          })
        }
        if (this.subOptions.length == 0 && this.filteredOptions.length == 0) {
          this.placeHolder = "No subscriptions available"
        } else if (this.filteredOptions.length != 0 && this.subOptions.length != 0) {
          this.subOptions = this.filteredOptions;
        }
        sessionStorage.removeItem("existingSub");
      })
    }
  }

  get subscriptions(): UntypedFormArray {
    return (this.formGroupDirective.form?.get('subscriptions') as UntypedFormArray) ?? this.fb.array([]);
  }

  populatePortfolio(selectedProductCode, termIndex){
    const selectedProduct = this.productData.find(item => item.productCode == selectedProductCode);
    const subscriptionItem = this.subscriptions.at(termIndex) as UntypedFormGroup;
    subscriptionItem.patchValue({portfolio: selectedProduct.portfolio});
    this.subOptions = this.subOptions.filter(item => item.productCode !== selectedProductCode);
    if (this.subOptions.length == 0) {
      this.placeHolder = "No subscriptions available";
    }
  }

  addSubscription(): void {
    const subscription = this.fb.group({
      productCode: [''],
      cascade: [''],
      portfolio: [''],
      activationDate: ['', [dateValidation('deactivationDate')]],
      deactivationDate: ['', [dateValidation('activationDate')]],
      cascadedSubscription: false,
      new: true
    },);
    this.subscriptions.push(subscription);
    this.cdr.detectChanges();
  }

  removeSubscription(index: number): void {
    this.subscriptions.removeAt(index);
  }

  formatDate(date: Date | string): string {
    if (date) {
      if (typeof date === 'string') {
        return new Date(date).toLocaleDateString('en-US');
      } else {
        return date.toLocaleDateString('en-US');
      }
    }
    return null;
  }

  openDatePicker(datePicker: MatDatepicker<Date>) {
      datePicker.open();
  }

}
