import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { REST_ENDPOINT } from 'src/app/app.constants';
import { environment } from '../../../environments/environment';
import { retry } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  SSOUrl = environment.SSOUrl;

  constructor(private httpClient: HttpClient, private cookieService: CookieService) { }

  /**
   * return token stored in cookie
   */
  getTokenFromCookie(): string {
    const token = this.cookieService.get('token');
    if (token && token.trim() !== '') {
      return token;
    }
    return null;
  }

  /*
  * return token stored in cookie
  */
  removeTokenFromCookie(): void {
    this.cookieService.remove('token');
  }

  /**
   *
   * @param token
   */
  saveTokenInCookie(token: string): string {
    if (token && token.trim() !== '') {
      this.cookieService.put('token', token);
      return token;
    }

    return null;
  }
  /**
   * return tenant id from local cookie
   */
  getTenantIdFromCookie(): string {
    const tenantId = this.cookieService.get('tenantId');
    if (tenantId && tenantId.trim() !== '') {
      return tenantId;
    }
    return null;
  }

  /**
  * remove tenant id from local cookie
  */
  removeTenantIdFromCookie(): void {
    this.cookieService.remove('tenantId');
  }

  /**
   *
   * @param key
   * @param value
   */
   saveInCookie(key:string , value: string| any) {
     if(!key && !value ) {
       return;
     }
     this.cookieService.put(key, value);
   }

  /**
   *
   * @param key
   */
  getFromCookie(key:string) {
    if(!key ) {
      return;
    }
    return this.cookieService.get(key);
  }

   /**
   *
   * @param tenantId
   */
  removeFromCookie(key:string) {
    if(!key) {
      return;
    }
    this.cookieService.remove(key);
  }

  saveTenantIdInCookie(tenantId: string): string {
    if (tenantId && tenantId.trim() !== '') {
      this.cookieService.put('tenantId', tenantId);
      return tenantId;
    }
    return null;
  }
  /**
   *
   * @param token
   * funtion verfies the token at server
   */
  verifyToken(token: string, tenantId: string): Observable<any> {
    if (!token || token.trim() === '') {
      return;
    }

    const url = `${this.SSOUrl}${REST_ENDPOINT.auth.verifyToken}`;

    // Request payload
    const payload = {
      token: token,
      tenantId: tenantId
    };

    return this.httpClient.post(url, payload).pipe(
      retry(1) // retry a failed request
    );
  }

  /**
   *
   * @param UUID
   */
  fetchTokenFromSSOServer(UUID: string): Observable<any> {
    if (!UUID || UUID.trim() === '') {
      return;
    }
    const url = `${this.SSOUrl}${REST_ENDPOINT.auth.fetchToken}/${UUID}`;

    return this.httpClient.get(url);
  }

  /**
   * fetch the token from sso server
   */
  loginSSO(): void {
    const encodedComponent = this.getRedirectUrl();
    const url = `${this.SSOUrl}${REST_ENDPOINT.auth.validate}?userid=${null}&redirecturl=${encodedComponent}`;
    window.location.href = url;
  }

  getAuthHttpOptions(): { headers: HttpHeaders } {

    // Get the auth token from the service.
    const authToken = this.getTokenFromCookie();

    const tenantId = this.getTenantIdFromCookie();
    const bearerToken = `Bearer ${authToken}`;

    const headers = new HttpHeaders()
      .set('Authorization', bearerToken)
      .set('tenant', tenantId)
      .set("trace-id", uuidv4())
      .set('x-apikey', environment.siqApiKey);
   return {
     headers
   }
 }

 getAuthHttpOptionsForFileUpload(): { headers: HttpHeaders } {
  const authToken = this.getTokenFromCookie();

  const tenantId = this.getTenantIdFromCookie();
  const bearerToken = `Bearer ${authToken}`;

  const headers = new HttpHeaders()
    .set('Authorization', bearerToken)
    .set('tenant', tenantId)
   return {
   headers
 }
}

getAuthHttpOptionsForFileUploadJSON(): { headers: HttpHeaders } {
  const authToken = this.getTokenFromCookie();

  const tenantId = this.getTenantIdFromCookie();
  const bearerToken = `Bearer ${authToken}`;

  const headers = new HttpHeaders()
    .set('Authorization', bearerToken)
    .set('tenant', tenantId)
    .set('Content-Type', "application/json")
   return {
   headers
 }
}

 getAuthHttpOptionsForUms(): { headers: HttpHeaders } {

  // Get the auth token from the service.
  const authToken = this.getTokenFromCookie();

  const tenantId = this.getTenantIdFromCookie();
  const bearerToken = `Bearer ${authToken}`;

  const headers = new HttpHeaders()
    .set('Authorization', bearerToken)
    .set('tenant', tenantId)
 return {
   headers
 }
}

  fetchUserInformation() {
    const url = `${this.SSOUrl}${REST_ENDPOINT.user.fetch}`;

    const token = this.getTokenFromCookie();

    if(!token) {
      return;
    }

    const payload = {
      token: token
    };

    return this.httpClient.post(url, payload);
  }

  /**
   * remove all cookies from
   */
  clearAllCookies() {
    this.cookieService.removeAll();
  }
  /**
   *
   */
  logout() {

    // fetch user id from cookie before deleting it.
    const userid = this.getFromCookie('uid');

    // remove all cokies from local browser
    this.clearAllCookies();

    // call api to remove cookie from server
    const encodedComponent = this.getRedirectUrl();
    const url = `${this.SSOUrl}${REST_ENDPOINT.auth.deleteTokn}?userid=${null}
    &redirecturl=${encodedComponent}`;


    // redirect logout to sso server
    window.location.href = url;
  }

  getRedirectUrl(): string {
    // const currentUrl = location.href;
    let origin;

    // if (environment.env === "DEV" || environment.env === "TEST" ||
    //   environment.env === "UAT") {
    //   const position = this.getCharPosition(currentUrl, '/', 4);
    //   origin = `${currentUrl.substr(0, position)}/#/`;
    // } else {
    origin = `${location.origin}/#/`;
    // }

    // call api to remove cookie from server
    return encodeURIComponent(origin);
  }

  getCharPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }



}
