import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from '../../../../services/toaster.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UploadHistoryDataSource } from 'src/app/misc/UploadHistoryDataSource';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { UploadManagerComponent } from '../upload-manager/upload-manager';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
    selector: 'upload-history-list',
    templateUrl: './upload-history-list.component.html',
    styleUrls: ['./upload-history-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UploadHistoryListComponent implements OnInit, AfterViewInit {

    loading = false;
    dataSource: UploadHistoryDataSource;
    data$: any;
    defaultData: any;
    today = (new Date()).toISOString().substring(0,10);
    dateRangeOptions = [
      { label: 'All', value: 'all' },
      { label: 'One Day', value: 'one-day' },
      { label: 'Last Week', value: 'last-week' },
      { label: 'One Month', value: 'one-month' },
      { label: 'Three Months', value: 'three-months' },
      { label: 'Six Months', value: 'six-months' },
      { label: 'One Year', value: 'one-year' },
    ];
    displayedColumns = ['id', 'createdAt', 'fileCount', 'fileType', 'status', 'action'];
    pageSize = 20;
    currentPage = 0;
    totalSize = 0;
    paginatedData: any[] = [];
    filteredData: any[] = [];


    selectedRange = 'all';
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    

    constructor(private spinner: NgxSpinnerService, private authService: AuthService, private toaster: ToasterService, 
        private fileUploadService: FileUploadService, private dialog: MatDialog) {
      this.dataSource = new UploadHistoryDataSource(fileUploadService, authService);
      this.dataSource.loadData().subscribe(res => {
        this.data$ = res.data;
        this.defaultData = res.data;
        this.filteredData = res.data;
        this.updatePaginatedData();
        this.applyDateFilter();
      });
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
      this.dataSource.init(this.paginator, this.sort);
    }

    openDialog(files: any[], timestamp: any) {
        const dialogRef = this.dialog.open(UploadManagerComponent, {
          height: '90%',
          width: '100%',
          panelClass: 'custom-dialog-container',
          autoFocus:false,
          data: {files: files, timestamp: timestamp}
        });
    }

    applyDateFilter() {
      const today = new Date();
      this.currentPage = 0;
      this.paginator.length = this.totalSize;
      this.paginator.pageIndex = 0;

      switch (this.selectedRange) {
        case 'all':
          this.filteredData = this.defaultData;
          break;
        case 'one-day':
          this.filteredData = this.defaultData.filter((item) => 
            this.isWithinDateRange(new Date(item.timeStamp), 1)
          );
          break;
        case 'last-week':
          this.filteredData = this.defaultData.filter((item) =>
            this.isWithinDateRange(new Date(item.timeStamp), 7)
          );
          break;
        case 'one-month':
          this.filteredData = this.defaultData.filter((item) =>
            this.isWithinDateRange(new Date(item.timeStamp), 30)
          );
          break;
        case 'three-months':
          this.filteredData = this.defaultData.filter((item) =>
            this.isWithinDateRange(new Date(item.timeStamp), 90)
          );
          break;
        case 'six-months':
          this.filteredData = this.defaultData.filter((item) =>
            this.isWithinDateRange(new Date(item.timeStamp), 180)
          );
          break;
        case 'one-year':
          this.filteredData = this.defaultData.filter((item) =>
            this.isWithinDateRange(new Date(item.timeStamp), 365)
          );
          break;
        default:
          this.filteredData = this.defaultData;
          break;
      }
      this.totalSize = this.filteredData.length;
      this.data$ = this.filteredData;
    }

    isWithinDateRange(date: Date, days: number): boolean {
      const today = new Date();
      const targetDate = new Date(today);
      targetDate.setDate(today.getDate() - days);
      return new Date(date) >= targetDate;
    }
      
    onPageChange(event: PageEvent) {
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
      this.updatePaginatedData();
    }
  
    updatePaginatedData() {
      const startIndex = this.currentPage * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      this.paginatedData = this.filteredData.slice(startIndex, endIndex);
      this.data$ = this.paginatedData;
    }
}
