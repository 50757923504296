import { Component, Input, OnInit } from '@angular/core';
import { req } from 'agent-base';
import { UPLOADING_STATUS } from 'src/app/app.constants';
import { Requests, uploadedFile } from 'src/app/misc/UploadHistoryDataSource';

@Component({
    selector: 'csv-upload-progress',
    templateUrl: './upload-progress.component.html',
    styleUrls: ['./upload-progress.component.scss']
})
export class UploadProgressComponent implements OnInit {

    @Input()
    element: any;

    progressValue = 0;
    progressColor;
    total = 0;
    success = 0;
    failure = 0;

    ngOnInit(): void {
        this.progressValue = this.getValue();
        this.progressColor = this.getColor(this.progressValue);
    }

    getColor(val) {
        if (val >= 70) return "progress-green";
        else return "progress-amber";
    }

    getTextColor(){
        if (this.element.status == "File_Uploaded") return "successful-txt"
        else return "failed-txt"
    }

    getValue() {
        let value = 0;
        this.total = this.element.files.length;

        for (var file of this.element.files) {
            if (file.status == "File_Uploaded") {
                this.success++;
            } else if (!UPLOADING_STATUS.includes(file.status)) {
                this.failure++;
            }
        }
        if (this.success && this.total) {
            value = parseInt(((this.success / this.total) * 100).toString())
        }
        return value;
    }

    showProgressBar() {
        for (var file of this.element.files) {
            if (UPLOADING_STATUS.includes(file.status)) {
                return true;
            }
        }
        return false;
    }
}