<div *ngIf="loading" class="loader-container">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <h1>Loading Device Information...</h1>
</div>
<div *ngIf="!loading" class="page-container">
  <div class="info-header">
    <div class="ml-3 my-auto">
      <h2 class="full-width" style="text-align: center;"><strong>File Upload Manager</strong></h2>
    </div>
  </div>
  <upload-history-list [buId]="buId"></upload-history-list>
</div>


