import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NgxFileDropModule } from 'ngx-file-drop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import { HeaderComponent } from './components/header/header.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { HomeComponent } from './components/home/home.component';
import { DatePipe } from '@angular/common';
import {ClipboardModule} from '@angular/cdk/clipboard';

import { CookieModule } from 'ngx-cookie';
import { AuthVerifyComponent } from './auth/components/auth-verify/auth-verify.component';
import { AuthProcessComponent } from './auth/components/auth-process/auth-process.component';
import { HttpClientModule } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSpinnerModule } from "ngx-spinner";
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { AddTenantComponent } from './components/tenants/add-tenant/add-tenant.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TenantComponent } from './pages/tenants/tenants-overview/tanant.component';
import { SuperAdminComponent } from './components/super-admin/super-admin.component';
import { httpInterceptorProviders } from './auth/http-interceptors';
import { CreateSuperAdminComponent } from './components/super-admin/create-super-admin/create-super-admin.component';
import { ManageTenantComponent } from './components/tenants/manage-tenant/manage-tenant.component';
import { AddOrgComponent } from './components/tenants/add-org/add-org.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { CreateTenantComponent } from './components/tenants/create-tenant/create-tenant.component';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MarketSegmentComponent} from './components/common/market-segment/market-segment.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SubscriptionsComponent} from './components/common/subscriptions/subscriptions.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { GeneralInformationComponent } from './components/common/general-information/general-information.component';
import { KeysComponent } from './components/common/keys/keys.component';
import { LocationInformationComponent } from './components/common/location-information/location-information.component';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import { CommonHeaderComponent } from './components/tenants/common-header/common-header.component';
import { SearchCardComponent } from './components/common/search-card/search-card.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { LoaderComponent } from './components/common/loader/loader.component';
import { CustomPaginatorDirective } from './directives/custom-paginator.directive';
import {BusinessUnitsOverviewComponent} from './pages/bu/business-units-overview/business-units-overview.component';
import { SiteEditorComponent } from './pages/sites/site-editor/site-editor.component';
import { SiteGeneralInfoComponent } from './components/sites/site-general-info/site-general-info.component';
import { NameValuePairsComponent } from './components/common/name-value-pairs/name-value-pairs.component';
import { CreateBusinessUnitComponent } from './pages/bu/create-business-unit/create-business-unit.component';
import { TenantsBusinessUnitComponent } from './pages/bu/tenants-business-unit/tenants-business-unit.component';
import { SitesOverviewComponent } from './pages/sites/sites-overview/sites-overview.component';
import { SiteFloorComponent } from './pages/sites/site-floor/site-floor.component';
import { FloorEditorComponent } from './pages/sites/floor-editor/floor-editor.component';
import { FloorGeneralInfoComponent } from './pages/sites/components/floor-general-info/floor-general-info.component';
import { FloorPlanComponent } from './pages/sites/components/floor-plan/floor-plan.component';
import { DialogBodyComponent } from './components/common/dialog-body/dialog-body.component';
import { DataMigrationComponent } from './pages/migration/data-migration/data-migration.component';
import { BusinessUnitsSitesComponent } from './pages/sites/business-units-sites/business-units-sites.component';
import { ZoneGeneralInformationComponent } from './components/zones/zone-general-information/zone-general-information.component';
import { CreateZoneComponent } from './pages/zones/create-zone/create-zone.component';
import { ToastComponent } from './components/common/toast/toast.component';
import { ViewZonesComponent } from './pages/zones/view-zones/view-zones.component';
import { RouterModule } from '@angular/router';
import { ViewChildZonesComponent } from './pages/zones/child-zone/child-zone.component';
import { LabelStructureComponent } from './components/common/label-structure/label-structure.component';
import { SiteDeviceComponent } from './pages/sites/site-device/site-device.component';
import { DeviceGeneralInfoComponent } from './pages/devices/components/device-general-info/device-general-info.component';
import { DeviceEditorComponent } from './pages/devices/device-editor/device-editor.component';
import { DeviceParentInfoComponent } from './pages/devices/components/device-parent-info/device-parent-info.component';
import { TenantsUsersOverviewComponent } from './pages/users/tenants-users-overview/tenants-users-overview.component';
import { CreateUserComponent } from './pages/users/create-user/create-user.component';
import { UserPasswordComponent } from './components/common/user-password/user-password.component';
import { UserRolesComponent } from './components/common/user-roles/user-roles.component';
import { UserAccessComponent } from './components/common/user-access/user-access.component';
import { DevicesOverviewComponent } from './pages/devices/devices-overview/devices-overview.component';
import { UploadHistoryComponent } from './pages/sites/upload-history/upload-history.component';
import { FileUploadComponent } from './components/common/file-upload/file-upload.component';
import { UploadHistoryListComponent } from './pages/sites/components/upload-history-list/upload-history-list.component';
import { UploadProgressComponent } from './pages/sites/components/upload-progress/upload-progress.component';
import { LabelsSitesComponent } from './components/common/labels-sites/labels-sites.component';
import { AddressValidationComponent } from './components/common/address-validation/address-validation.component';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import { UploadManagerComponent } from './pages/sites/components/upload-manager/upload-manager';
import { UploadCardComponent } from './components/upload-card/upload-card.component';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    TenantComponent,
    SuperAdminComponent,
    AuthVerifyComponent,
    AuthProcessComponent,
    AddTenantComponent,
    CreateSuperAdminComponent,
    ManageTenantComponent,
    AddOrgComponent,
    SideBarComponent,
    CreateTenantComponent,
    MarketSegmentComponent,
    SubscriptionsComponent,
    GeneralInformationComponent,
    KeysComponent,
    LocationInformationComponent,
    CommonHeaderComponent,
    SearchCardComponent,
    LoaderComponent,
    CustomPaginatorDirective,
    BusinessUnitsOverviewComponent,
    SiteEditorComponent,
    SiteGeneralInfoComponent,
    NameValuePairsComponent,
    CreateBusinessUnitComponent,
    TenantsBusinessUnitComponent,
    SitesOverviewComponent,
    SiteFloorComponent,
    FloorGeneralInfoComponent,
    FloorEditorComponent,
    FloorPlanComponent,
    DialogBodyComponent,
    DataMigrationComponent,
    BusinessUnitsSitesComponent,
    ZoneGeneralInformationComponent,
    CreateZoneComponent,
    ToastComponent,
    ViewZonesComponent,
    ViewChildZonesComponent,
    LabelStructureComponent,
    SiteDeviceComponent,
    DeviceGeneralInfoComponent,
    DeviceParentInfoComponent,
    DeviceEditorComponent,
    TenantsUsersOverviewComponent,
    CreateUserComponent,
    UserPasswordComponent,
    UserRolesComponent,
    UserAccessComponent,
    DevicesOverviewComponent,
    FileUploadComponent,
    UploadProgressComponent,
    UploadHistoryListComponent,
    UploadHistoryComponent,
    LabelsSitesComponent,
    AddressValidationComponent,
    UploadManagerComponent,
    UploadCardComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    CookieModule.forRoot(),
    AgGridModule.withComponents([]),
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatCardModule,
    MatChipsModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    NgxFileDropModule,
    MatTooltipModule,
    ClipboardModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    MatProgressBarModule,
    MatMomentDateModule
  ],
  providers: [
    DatePipe,
    httpInterceptorProviders,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        horizontalPosition: 'end',
        verticalPosition: 'top',
        duration: 3000,
        panelClass: 'text-pre'
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
