import version from '../../package.json';

export const environment = {
  production: false,
  SSOUrl : 'https://auth-dev.sso.sensormatic.com', // SSO Prod url
  userManagementUrl: 'https://user-management-service-dev.sso.sensormatic.com',
  siqLocationUrl: 'https://location-management.sns-iq-dev.sensormatic.com',
  redirectUrl: '/auth-process', // prod redirect url
  env: 'DEV',
  apigeeLocationUrl: 'https://apigateway-dev.sensormatic.com/v1/location',
  fileIngestionUrl: 'https://apigateway-dev.sensormatic.com/api/v1/files',
  validateAddressUrl: 'https://addressvalidation.googleapis.com/v1:validateAddress',
  ValidateAddressApiKey: "AIzaSyBBr4mKfbMjwF8KEPJjH7JUF1Uc0RLSGCo",
  siqApiKey: 'bAErqGj1iR12gkkHubghtTxx8McW56EV6vxQVqTX5OrvdRJk',
  fileApiKey: '8PVaocVY8dQ82TIFG4gsGAIwBpWC3hG4OBK1GbxImunAvUlV',
  siqDeviceURL: 'https://device-management.sns-iq-dev.sensormatic.com',
  apigeeDeviceURL: 'https://apigateway-dev.sensormatic.com/v1/device-mgmt',
  localLocationUrl: 'http://localhost:8090',
  vers: version
};
